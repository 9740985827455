/* styles.css */
:root {
    --primary-color: #03c7ff !important;
    --background-color: #ebebeb !important;
    --text-color: #03c7ff !important;
    --shadow: #03c7ff !important;
    --btn-text-color: #ebebeb !important;
    --grey-text-color: #747474 !important;
}

/* Dark Mode */
[data-theme='dark'] {
    --primary-color: #03c7ff !important;
    --background-color: #202020 !important;
    --text-color: #00c8ff !important;
    --shadow: #03c7ff !important;
    --btn-text-color: #000 !important;
    --btn-bg-color: #03c7ff !important;
    --logo-text-color: #000 !important;
    --timer-text-color: #c0c0c0 !important;
    --hover-color: #cef4ff !important;
}

/* Light Mode */
[data-theme='light'] {
    --primary-color: #03c7ff !important;
    --background-color: #ededed !important;
    --text-color: #03c7ff !important;
    --shadow: #03c7ff !important;
    --btn-text-color: #ffffff !important;
    --btn-bg-color: #03c7ff !important;
    --logo-text-color: #ffffff !important;
    --timer-text-color: #aaaaaa !important;
    --hover-color: #0087ad !important;
}

/* Blue Mode */
[data-theme='blue'] {
    --primary-color: #000 !important;
    --background-color: #00a9d8 !important;
    --text-color: #000 !important;
    --shadow: #000000 !important;
    --btn-text-color: #03c7ff !important;
    --btn-bg-color: #000000 !important;
    --logo-text-color: #03c7ff !important;
    --timer-text-color: #e1e1e1 !important;
    --hover-color: #cef4ff !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

p,
a {
    margin: 0;
    padding: 0;
}

a:hover {
    color: var(--hover-color) !important;
    cursor: pointer !important;
}

.hover:hover {
    color: var(--hover-color) !important;
    cursor: pointer !important;
}

.light-text-hover:hover {
    color: #000000 !important;
}

a:link {
    text-decoration: none;
    margin-right: 20px;
}

.shadowed {
    box-shadow: var(--shadow) 0px 50px 100px -20px, var(--shadow) 0px 30px 60px -30px, var(--shadow) 3px 0px 10px -5px inset;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    top: 0 !important;
    /* somewhere there is Top: 40px on the body element, the above fixed that for now */
}

body>.skiptranslate {
    display: none;
}


/* .button {
    background-color: var(--btn-bg-color);
    color: var(--btn-text-color);
}

.button:hover {
    background-color: var(--hover-color);
    color: var(--primary-color);
    border: none;
    cursor: pointer;
} */

.link {
    background-color: transparent;
    color: var(--text-color);
    border: none;
    margin: 1rem;
    cursor: pointer;
}

.link:hover {
    background-color: none;
    color: var(--hover-color);
    border: none;
    margin: 1rem;
    cursor: pointer;
}

/* for those pesky higlighters */
.no-box-shadow {
    box-shadow: none;
    outline: none;
    background-color: var(--logo-text-color);
    border: 2px solid rgba(206, 206, 206, 0.5);
}

input {
    box-shadow: none;
    border: 0;
    outline: none;
    background-color: var(--logo-text-color);
    color: var(--text-color);
}

input::placeholder {
    color: var(--text-color);
}

.App {
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
}

.round {
    border-radius: 2rem;
}

.border-small {
    border: 1px solid lightgray;
}

.NewsArticle__image {
    height: 10rem;
    width: 100%;
    margin: auto;
    border-radius: 1rem;
    object-fit: cover;
}

.jumbo-text {
    font-size: 12rem;
    text-shadow: 2px 3px 10px var(--shadow);
    /* x-offset, y-offset, blur-radius, shadow-color */
    color: var(--logo-text-color);
}

.slogan-text {
    font-size: 1.4rem;
    color: var(--text-color);
}

/* News */

.News {
    height: 100vh;
}

/* NewsSearch */

.newsSearch__button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: 1px solid var(--text-color);
}

.newsSearch__button:hover {
    border: 1px solid var(--hover-color);
    color: var(--primary-color);
}

.newsSearch>input {
    width: 10rem;
}

.VertRectCard {
    width: 26rem;
    height: 29rem;
    background-color: var(--logo-text-color);
    overflow: hidden;
}

.VertRectCard>img {
    width: 26rem;
    height: 20rem;
    object-fit: cover;
}

/* Countdown Timer */

.CountdownTimer__timerText {
    font-size: 1.4rem;
    font-family: monospace;
    color: var(--timer-text-color);
}

/* Spinner */

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
    box-sizing: border-box;
}

.lds-spinner {
    color: var(--text-color);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3.2px;
    left: 36.8px;
    width: 6.4px;
    height: 17.6px;
    border-radius: 20%;
    background: var(--text-color);
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

/* error page */
.errorPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* header */
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    z-index: 1;
    /* position: relative;
    position: sticky; */
}

.header__left {
    display: flex;
    padding-left: 1rem;
}

.header__right {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.header__right>img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    border: 2px solid var(--timer-text-color);
    cursor: pointer;
}

.header__right>p {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    border: 2px solid #e0e1dd;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

img.navbar-coin {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}

.header__logoText {
    /* text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
    color: var(--text-color);
    font-weight: 600;
    transition: 0.5s;
    z-index: 15 !important;
}

.header__logoText:hover {
    font-weight: 600;
    color: var(--hover-color);
    transition: 0.5s;
}

.header__joinNowButton:hover {
    background-color: transparent;
    color: #0d6efd;
    transition: 0.5s;
}

.header__joinNowButton {
    background-color: transparent;
    color: #e0e1dd;
}

.googleSignin__button {
    color: #000;
}

.googleSignin__button:hover {
    color: #000 !important;
    background-color: rgb(236, 236, 236);
}

/* Define the default background image */
.google-logo {
    display: inline-block;
    width: 25px;
    /* Adjust to your image dimensions */
    height: 25px;
    background-image: url('./assets/g-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: -20px;
}

.appleSignin__button {
    color: #000;
}

.appleSignin__button:hover {
    background-color: rgb(236, 236, 236);
    color: rgb(0, 0, 0) !important;
}

.xSignin__button {
    background-color: #000;
    color: white;
}

.xSignin__button:hover {
    color: rgb(255, 255, 255) !important;
    background-color: #000;
    transition: 0.5s ease;
}

/* Define the default background image */
.x-logo {
    display: inline-block;
    width: 15px;
    /* Adjust to your image dimensions */
    height: 15px;
    background-image: url('./assets/logo-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: -20px;
}

/* Define the default background image */
.facebook-logo {
    display: inline-block;
    width: 15px;
    /* Adjust to your image dimensions */
    height: 15px;
    background-image: url('./assets/Facebook_Logo_Primary.png');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: -20px;
}

.facebookSignin__button {
    background-color: #4267b2;
    color: white !important;
    border: 1px solid #4267b2;
}

.facebookSignin__button:hover {
    background-color: #4267b2;
    color: white !important;
    border: 1px solid #4267b2;
}

.header__searchContainer {
    position: relative;
}

/* sidebarNavigator */
.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: var(--background-color);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    z-index: 20;
}

.sidebar a,
button {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: var(--text-color);
    display: block;
    transition: 0.5s;
    border: none;
    background-color: transparent;
}

.sidebar a:hover,
button:hover {
    color: var(--hover-color) !important;
    transition: 0.5s;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    padding: 0;
    background-color: transparent;
    color: var(--text-color);
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}

.openbtn:hover {
    background-color: #444;
    transition: 0.5s;
}

.ThemeSwitcher>p {
    color: var(--text-color);
    transition: 0.5s;
}

.ThemeSwitcher>p:hover {
    color: var(--hover-color);
    transition: 0.5s;
}

/* Set a max-width for the translate widget container */
#google_translate_element {
    position: absolute;
    top: 4px;
    right: 22%;
    z-index: 10;
    /* max-width: 160px; */
    /* Adjust this value to fit your layout */
    /* overflow: hidden; Hide any overflow if the content exceeds the max-width */
}

/* Reduce the size of the dropdown */
.goog-te-gadget-simple {
    font-size: 12px;
    /* Adjust the font size */
    line-height: 1.2;
    /* Adjust the line height */
    padding: 2px 4px;
    /* Adjust the padding */
    z-index: 10;
}

/* Customize the width of the dropdown */
.goog-te-gadget-simple .goog-te-menu-value {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Ellipsis for overflowing text */
}

#searchInput {
    z-index: 5 !important;
}

/* Login Modal */
.yahooSignin__button {
    color: #000 !important;
    border: 1px solid #000;
}

.yahooSignin__button:hover {
    background-color: #000 !important;
    border: 1px solid #000;
    transition: 0.5s;
}

.loginModal__wurkloLogo {
    width: 10rem;
    height: 10rem;
    border-radius: 6rem;
}

.loginModal__logoText {
    font-size: 3rem;
    font-weight: 600;
    color: #00ddff;
}

.loginModal__button {
    color: var(--text-color);
    border-color: var(--text-color);
    border-radius: 6rem;

}

.loginModal__button:hover {
    color: white;
    border-color: var(--hover-color);
    background-color: transparent;
    transition: 0.5s;
    cursor: pointer;
}

/* join now modal */
.joinNow__button:hover {
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: 0.5s;

}

.joinNow__button {
    color: var(--text-color);
}

.JoinNowModal__footer>p {
    font-size: 0.85rem;
    color: rgb(111, 111, 111);
}


@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* homepage */
.HomePage__GeoWatch {
    /* background-image: url('../src/assets/OrionOrange_Grelin_1080.jpg'); 
    background-position: center;
    background-repeat: no-repeat;  */
}

/* media queries */
@media screen and (prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth;
    }
}

@media screen and (max-width: 550px) {
    .jumbo-text {
        font-size: 6rem;
    }

    .slogan-text {
        font-size: 0.9rem;
    }

    .App {
        min-height: 93vh;
    }

    .CountdownTimer__timerText {
        font-size: 0.8rem;
    }

    .VertRectCard {
        width: 100%;
        max-width: 35rem;
        height: auto;
        background-color: var(--logo-text-color);
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .VertRectCard>img {
        width: 100%;
        height: auto;
        /* Allow the height to adjust automatically */
        object-fit: cover;
    }

    .header__right>img {
        width: 2.2rem;
        height: 2.2rem;
    }

    .header__right>p {
        width: 2.2rem;
        height: 2.2rem;
    }

    #myTopbar {
        width: 100% !important;
    }

    .manual__container {
        width: 100% !important;
    }
    .hero-background .background-image {
        object-fit: cover !important;
      }
}


@media screen and (max-width: 1000px) {

    /* Set a max-width for the translate widget container */
    #google_translate_element {
        position: fixed;
        top: 3rem;
        left: 0.5rem;
        /* max-width: 160px; */
        /* Adjust this value to fit your layout */
        /* overflow: hidden; Hide any overflow if the content exceeds the max-width */
    }
}

@media screen and (min-width: 577px) and (max-width: 770px) {}

@media screen and (max-width: 350px) {
    .newsSearch>input {
        width: 8rem;
    }

}

/* Style for the dropdown container */
/* .dropdown-container {
        position: fixed;
        width: 20rem;
        height: 0;
        background-color: var(--background-color);
        transition: height 0.5s ease, width 0.5s ease !important;
        z-index: 5; 
        padding-top: 10px;
        display: none;
        border: 2px solid var(--primary-color);
        left: 50%;
        top: 120px;
        transform: translate(-50%, -50%);
    } */

/* Search input expansion when dropdown is open */
/* #searchInput {
        position: fixed;
        transition: height 0.5s ease, width 0.5s ease !important;
        z-index: 10;
        left: 50%;
        top: 27px;
        transform: translate(-50%, -50%);
    } */

/* Transparent overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(186, 186, 186, 0.2);
    z-index: 1;
    display: none;
    transition: width 0.5s ease;
}

.overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(186, 186, 186, 0.2);
    z-index: 5;
    display: none;
    transition: width 0.5s ease;
}


/* Topbar styling */
#myTopbar {
    width: 30%;
    height: 0;
    position: fixed;
    top: 0;
    left: 50%;
    background-color: var(--background-color);
    box-shadow: var(--shadow) 0px 20px 30px -20px, var(--shadow) 20px 0px 60px -30px, var(--shadow) 0px -6px 10px -5px inset;
    /* overflow-y: hidden; */
    transition: height 0.5s ease;
    z-index: 3;
    transform: translate(-50%, -0%);
    border-top-left-radius: 0;
}

#myTopbar a,
button {
    padding: 8px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    background-color: white;
}

#myTopbar a:hover,
button:hover {
    color: var(--hover-color)
}

#myTopbar .closebtn {
    color: var(--text-color);
}

#searchInput {
    transition: width 0.5s ease-in-out;
    /* Smooth transition */
}

#searchInput.expanded {
    width: 300px;
    /* Expanded width */
}

.rounded-start-custom {
    border-top-left-radius: 0.25rem;
    /* Equivalent to rounded-start-2 */
    border-bottom-left-radius: 0.25rem;
}

.rounded-end-custom {
    border-top-right-radius: 0.25rem;
    /* Equivalent to rounded-start-2 */
    border-bottom-right-radius: 0.25rem;
}

/*  Homepage Clocks */
/* Digital clock style for the city time */
.city-time {
    font-family: 'Courier New', Courier, monospace;
    /* Monospace for digital look */
    font-size: 1rem;
    /* Large font size for the clock */
    font-weight: bold;
    letter-spacing: 0.03rem;
    background-color: #000;
    /* Black background for the war room feel */
    color: #00FF00;
    /* Neon green text like a digital display */
    padding: 0rem 1.5rem;
    border-radius: 5px;
}

/* Styling for the city names */
.city-name {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.1rem;
    color: #fff;
    /* White text for the city name */
    border-radius: 5px;
    margin-bottom: 0.1rem;
}

/* Container to hold each clock section */
.city-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    /* Overall dark background for contrast */
    border-left: 1px solid gray;
}


/* Social media post */

/* Post Card Container */
.post-card {
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    max-width: 600px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* User Info */
.post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.post-header img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.post-user-info h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.post-user-info p {
    margin: 0;
    font-size: 14px;
    color: #555;
}

/* Post Content */
.post-content p {
    font-size: 14px;
    margin-bottom: 10px;
}

.post-content img,
.post-content video {
    width: 100%;
    max-height: 60vh;
    object-fit: cover;
    border-radius: 8px;
}

/* Post CTA Button */
.cta-button {
    background-color: #ff9800;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
}

.cta-button:hover {
    background-color: #f57c00;
}

/* Post Footer */
.post-footer {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}

/* Reactions */
.post-reactions {
    display: flex;
    gap: 15px;
}

.post-reactions p {
    margin: 0;
    cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 768px) {

    .post-card,
    .post-creator {
        width: 100%;
    }
}



/* Post Creator Container */
.post-creator {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Text Area */
.post-creator textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 14px;
}

/* File Input */
.post-creator input[type="file"] {
    width: 100%;
}

.image-upload input {
    display: none;
}

.image-upload {
    color: white;
    border-radius: 5px;
    cursor: pointer;
    height: 50%;
}

/* Hashtags, Mentions, CTA, etc. */
.post-creator input[type="text"],
.post-creator select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* CTA Button */
.post-creator button {
    background-color: #03a9f4;
    color: white;
    border: none;
    padding: 0px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 50%;
}

.post-creator button:hover {
    background-color: #0288d1;
}

.post-image-preview {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 10px;
}

.post-creator-actions {
    display: flex;
    justify-content: space-between;
    height: 50%;
}

/* Globe.js */
/* Styling for the globe container */
.globe-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: -1;
    /* Ensures it's in the background */
}

/* Styling for the globe */
.globe {
    width: 300px;
    /* Adjust size as needed */
    height: 300px;
    border-radius: 50%;
    background: radial-gradient(circle at 30% 30%, #0066ff 40%, #0044cc 60%, #009900 85%);
    box-shadow: inset -15px 10px 50px rgba(0, 0, 0, 0.5);
    animation: rotate 20s infinite linear;
}

/* Rotation animation */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/* PostComments */

.PostComments {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-top: 20px;
}

.PostComments__list {
    list-style-type: none;
    padding-left: 0;
}

.PostComments__list li {
    margin-bottom: 10px;
}

.PostComments__input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.PostComments__button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.PostComments__button:hover {
    background-color: #0056b3;
}

.PostComments__comments {
    height: 50vh !important;
    overflow-y: auto;
}

.profileAvatar {
    border-radius: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
}

/* ChatPage */

.chat-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.chat-window {
    height: 100%;
    width: 100%;
}

.chat-messages {
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
    margin-bottom: 60px;
    margin-top: 60px;
}

.message {
    margin-bottom: 10px;
    /* Ensure each message doesn't create its own scrollbar */
}

.chat-input {
    display: flex;
    position: fixed;
    padding: 10px;
    background-color: blur();
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
}

.blur-background {
    background: rgba(255, 255, 255, 0.1);
    /* Semi-transparent background */
    backdrop-filter: blur(10px);
    /* Adjust the blur amount */
}

input {
    flex-grow: 1;
    padding-left: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.emoji-picker {
    position: fixed;
    bottom: 3.5rem;
}

.emoji-picker-button {
    position: absolute;
    bottom: 0.2rem;
    right: 5rem;
}

.PublicChat__errorMessage {
    right: 0;
    bottom: 0;
    z-index: 10;
}

/*  bankpage */

/* BankPage container */
.bank-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial, sans-serif';
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* background-color: #f9f9f9; */
}

/* Header styles */
.bank-header {
    display: flex;
    align-items: center;
    width: 86%;
}

.bank-header-text {
    flex-grow: 1;
    font-size: 1.8em;

    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
}

/* Profile section */
.profile-section {
    text-align: center;
}

.profile-section img {
    border-radius: 50%;
    margin-bottom: 20px;
    border: 3px solid #b2b2b2;
}

.profile-section button {
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 1.3rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: white;
    transition: background-color 0.3s ease;
}

.button {
    margin: 0 10px;
    padding: 5px 20px;
    font-size: 1.3rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: white;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #45a049;
    color: white !important;
}

.profile-section button:hover {
    background-color: #45a049;
}

.ProfilePage__footer>a {
    font-size: 0.85rem;
    color: var(--timer-text-color);
}

.ProfilePage__footer>p {
    font-size: 0.85rem;
    color: var(--timer-text-color);
}

/* Transactions section */
.transactions-section {
    margin-top: 30px;
    font-family: 'Arial, sans-serif';
}

.transactions-section h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.transactions-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: auto;
}

.transactions-section li {
    padding: 10px;
    /* background-color: #fff; */
    border-top: 1px solid #4c4c4c;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}

.transactions-section .transaction-row {
    display: flex;
    justify-content: space-around;
    min-width: 800px;
    /* Adjust to ensure all columns fit in one row */
    flex-shrink: 0;
    /* Prevent shrinking */
}

.transactions-section a {
    display: block;
    text-decoration: none;
    color: #4caf50;
    margin-top: 10px;
    text-align: right;
    transition: color 0.3s ease;
}

.transactions-section a:hover {
    color: #388e3c;
}

/* Receivepage */

.ReceivePage__header> :last-child {
    top: 5%;
    left: 50%;
    transform: translate(-50%);
}

.text-lightgreen {
    color: #73ff99;
}

.text-lightred {
    color: #ff7171;
}

.text-grey {
    color: var(--timer-text-color);
}

/* Profile Page Styles */
.profile-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-photo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 3px solid #0077cc;
}

.profile-header h1 {
    margin: 0;
    font-size: 1.8em;
}

.profile-header p {
    margin: 5px 0;
}

.profile-info,
.profile-details,
.profile-preferences,
.profile-permissions,
.profile-contacts {
    margin-bottom: 20px;
    padding: 15px;
    /* background-color: #fff; */
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-top: 0;
    font-size: 1.4em;
    color: var(--timer-text-color);
    font-weight: bold;
}

.profile-info p,
.profile-details p,
.profile-preferences p,
.profile-permissions p,
.profile-contacts p {
    margin: 8px 0;
}

/* textarea,
  input[type="text"],
  select {
    width: calc(100% - 20px);
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  textarea {
    min-height: 60px;
    resize: vertical;
  } */

button:hover {
    background-color: #005fa3;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

a {
    white-space: nowrap;
}

/* Skeleton Container */
.skeleton-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Skeleton Animation */
@keyframes skeleton-loading {
    0% {
        background-color: #9f9f9f;
    }

    50% {
        background-color: #6c6c6c;
    }

    100% {
        background-color: #a1a1a1;
    }
}

/* Circle Skeleton */
.skeleton-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ffffff;
    animation: skeleton-loading 1.2s ease-in-out infinite;
}

/* Rectangular Skeleton */
.skeleton-rect {
    background-color: #ffffff;
    border-radius: 4px;
    height: 15px;
    animation: skeleton-loading 1.2s ease-in-out infinite;
}

/* Widths for Rectangular Skeletons */
.skeleton-width-sm {
    width: 50px;
}

.skeleton-width-md {
    width: 100px;
}

.skeleton-width-lg {
    width: 150px;
}


/* Manual page */

/* Container and Layout */
.manual__container {
    display: flex;
    height: 95vh;
    width: 50%;
}

.manual__sidebar {
    width: 60px;
    /* Initial width */
    background-color: #2c3e50;
    padding: 10px;
    transition: width 0.3s ease;
    overflow-x: hidden;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.manual__sidebar:hover {
    width: 150px;
    /* Expand width on hover */
}

.manual__sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.manual__sidebar ul li {
    padding: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: #ecf0f1;
    transition: background-color 0.3s, padding 0.3s;
    display: flex;
    align-items: center;
}

.manual__sidebar ul li.active,
.manual__sidebar ul li:hover {
    background-color: #34495e;
}

.manual__sidebar ul li .label-text {
    margin-left: 10px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* Show text when the sidebar is expanded */
.manual__sidebar:hover ul li .label-text {
    opacity: 1;
}

/* Content Area */
.manual__content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.manual__separator {
    border: none;
    border-top: 1px solid #ecf0f1;
    margin: 15px 0;
}


.no-wrap {
    white-space: nowrap;
}

/*  manual section get help*/

.get-help-box {
    border-radius: 10px;
    text-align: left;
    font-family: 'Arial', sans-serif;
}

.get-help-box p {
    margin: 10px 0;
    font-size: 1.1em;
}

.get-help-ticket {
    margin-top: 15px;
}

.get-help-ticket input {
    padding: 10px;
    width: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.get-help-ticket button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 1em;
}

.get-help-ticket button:hover {
    background-color: #0056b3;
}


/* TermsPrivacy.css */

.TermsPrivacy__container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
}

/* Summary Section Styling */
.summary {
    background-color: var(--hover-color);
    padding: 20px;
    border-left: 5px solid var(--timer-text-color);
    margin-bottom: 40px;
    color: var(--btn-text-color);
}

.summary h2 {
    margin-top: 0;
    color: var(--btn-text-color);
}

.summary ul {
    list-style-type: disc;
    margin-left: 40px;
}

.summary p {
    margin-bottom: 10px;
}


/* Landing page */

.LandingPage__container {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #202020;
    color: #333;
}
/* Hero Section */
.hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* margin-left: 10rem; */
  }
  
  .hero-content {
    z-index: 1;
    color: white;
  }
  
  .hero-title {
    font-size: 48px;
    margin: 0;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.9);
  }
  
  .hero-subtitle {
    font-size: 24px;
    margin: 20px 0;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.9);
  }
  
  .hero-background .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 0;
    opacity: 0.5;
  }

  /* subsections */
  .subsection {
    position: relative;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* margin-left: 10rem; */
  }
  
  /* Features Section */
  .features-section {
    padding: 50px 20px;
    background-color: #fff;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .feature-item {
    text-align: center;
  }
  
  .feature-item img {
    width: 290px;
    height: 200px;
  }
  
  .feature-item h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .feature-item p {
    font-size: 16px;
    color: #777;
  }
  
  /* Call to Action Section */
  .cta-section {
    color: white;
    text-align: center;
    padding: 40px 20px;
  }
  
  .cta-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .cta-button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #03c7ff;
    color: #000000;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: transform 0.3s ease-in-out;
  }
  
  .cta-button:hover {
    background-color: #ffffff;
    color: #000000 !important;
    transform: scale(0.95);
  }
  
  /* Footer */
  .footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer p {
    margin: 10px 0;
}

.social-icons {
    margin: 10px 0;
}

.social-icons a img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
}

.social-icons a:hover img {
    opacity: 0.7;
  }